import React, { useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/LayoutComponents/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'

function Page404({ data }) {
  const [state, setState] = useState()
  const element = useRef()
  useEffect(() => {
    setState(window.outerHeight - element.current.clientHeight)
  }, [])
  return (
    <div ref={element}>
      <Hero
        colorheading="404"
        centered
        image={data.contactUs.childImageSharp.gatsbyImageData}
        heading=" Not Found"
        split
      />
      <Seo title="404: Not Found" />
      <Layout>
        <div
          style={{ height: `${state}px` }}
          className="flex flex-col items-center justify-center space-y-4 bg-light-black text-my-yellow"
        >
          <p className="text-2xl font-semibold">Hmm, page doesn't exist</p>
          <p className="text-lg text-my-grey">Looks like you got lost!</p>
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query Page404Query {
    contactUs: file(relativePath: { eq: "compass.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default Page404
